<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row>
              <b-col cols="12">
                <div class="text-right">
                  <b-button
                    v-show="dataSend.status == 3"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    size="sm"
                    @click="showModalVangMat"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="UserMinusIcon" /> Xét vắng mặt
                    </span>
                  </b-button>
                  <b-button
                    v-show="dataSend.status == 3"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="ml-1"
                    @click="onUpdateExamConditionsAutomatically"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="FilterIcon" /> Xét duyệt điều kiện thi
                    </span>
                  </b-button>
                  <b-button
                    v-if="creatable"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.creditClassStudentSaveModal
                    variant="success"
                    size="sm"
                    class="ml-1"
                    @click="onCreateMainData"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="PlusIcon" /> Thêm mới
                    </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <vue-good-table
                  mode="remote"
                  class="my-table"
                  max-height="500px"
                  row-style-class="vgt-row"
                  :line-numbers="true"
                  :columns="columns"
                  :rows="mainData"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  :select-options="{ enabled: true, selectOnCheckboxOnly: true, selectionText: 'sinh viên được chọn', clearSelectionText: 'Bỏ chọn' }"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  @on-selected-rows-change="selectionChanged"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'status'">
                      {{ getStatusName(props.row.status) }}
                    </span>
                    <span v-else-if="props.column.field === 'isEligible'">
                      {{ getEligibleName(props.row.isEligible) }}
                    </span>
                    <span v-else-if="props.column.field === 'absentTime'">
                       <span style="cursor: pointer; text-decoration:underline;" @click="showDetailAbsentTime(props.row)">{{props.row.absentTime}}</span>
                    </span>
                    <span
                      v-else-if="props.column.field === 'action'"
                      class="thead-group-action"
                    >
                      <b-button-group>
                        <b-button
                          v-if="updatable"
                          v-b-modal.creditClassStudentSaveModal
                          variant="primary"
                          class="btn-icon"
                          size="sm"
                          title="Chỉnh sửa"
                          @click="onEditMainData(props.row)"
                        >
                          <feather-icon icon="Edit2Icon" />
                        </b-button>
                        <b-button
                          v-if="deletable"
                          variant="danger"
                          class="btn-icon"
                          size="sm"
                          title="Xóa"
                          @click="onDeleteMainData(props.row)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </b-button-group>
                    </span>
                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <CreditClassStudentSave
      ref="creditClassStudentSaveFormRef"
      :main-data="currentMainData"
      :ext-data="extMainData"
      @succeed="onSucceed"
    />
    <b-modal
      id="modal-center"
      :hide-footer="true"
      size="lg"
      title="Đánh dấu vắng mặt"
    >
      <b-form @submit="onSubmitFormVangMat">
        <b-form-group
          label="Ngày vắng mặt:"
          label-for="start_date"
        >
          <b-form-datepicker
            id="datepicker-dateformat1"
            v-model="formVangmat.date"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="vi"
            format="dd/mm/yyyy"
          />
        </b-form-group>
        <b-form-group
          id="input-group-2"
          label="Tiết vắng mặt:"
          label-for="input-2"
        >
          <b-form-checkbox-group
            id="checkbox-group-turn-1"
            v-model="formVangmat.turns"
            class="scheduler-detail"
            :options="optTurns"
            name="flavour-1"
          />
        </b-form-group>

        <b-form-group
          id="input-group-1"
          label="Lý do:"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="formVangmat.lydo"
            placeholder="Nhập lý do vắng mặt"
            required
          />
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label-for="input-2"
        >
          <b-form-checkbox
            v-model="formVangmat.sendNoti"
            :value="1"
            :unchecked-value="0"
          >
            Có gửi thông báo tới sinh viên
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          id="input-group-2"
          label-for="input-2"
        >
          <b-form-checkbox
            v-model="formVangmat.sendSms"
            :value="1"
            :unchecked-value="0"
          >
            Có gửi tin nhắn tới sinh viên
          </b-form-checkbox>
        </b-form-group>

        <b-button
          type="submit"
          variant="success"
          style="margin-top: 20px;"
        >
          Xác nhận
        </b-button>
      </b-form>
    </b-modal>
    <b-modal
      ref="modal-detail-absent"
      id="modal-detail-absent"
      ok-only
      ok-title="Chấp nhận"
      centered
      size="lg"
      title="Chi tiết vắng mặt"
      :hide-footer=true
    >
      <DetailAbsentLogs :dataSend="dataSendAbsent" />
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BButtonGroup, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow, BFormCheckboxGroup, BForm, BFormGroup, BFormInput, BFormCheckbox,
  BFormDatepicker,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import CreditClassStudentSave from '@/views/credit-class-student/CreditClassStudentSave.vue'
import DetailAbsentLogs from '@/views/credit-class-student/DetailAbsentLogs.vue'
import moment from 'moment'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

export const Attr = Object.freeze({
  store: 'creditClassStudent',
})
export default {
  name: 'CreditClassStudent',
  directives: {
    Ripple,
  },
  components: {
    CreditClassStudentSave,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    BFormCheckboxGroup,
    BFormGroup,
    BFormInput,
    BForm,
    BFormDatepicker,
    BFormCheckbox,
    DetailAbsentLogs,
  },
  props: {
    dataSend: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        creditClassId: this.dataSend.id || this.$route.params.id,
        isEligible: '',
        sort: '1_asc',
      },
      currentMainData: undefined,
      extMainData: { creditClassId: this.dataSend.id || this.$route.params.id },
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      formVangmat: {
        lydo: '',
        turns: [],
        date: '',
        sendNoti: '0',
        sendSms: '0',
      },
      optTurns: [],
      dsSVChon: [],
      dataSendAbsent: {},
    }
  },
  computed: {
    ...mapGetters({
      mainData: `${Attr.store}/dataLists`,
      totalRows: `${Attr.store}/totalRows`,
      statuses: `${Attr.store}/statuses`,
      eligible: `${Attr.store}/eligible`,
      resourceName: `${Attr.store}/resourceName`,
      turns: 'turn/turns',
      listSchedule: 'schedule/schedulers',
    }),
    statusFilterDropdownItems() {
      return this.statuses.map(item => ({ value: item.value, text: item.label }))
    },
    eligibleFilterDropdownItems() {
      return this.eligible.map(item => ({ value: item.value, text: item.label }))
    },
    columns() {
      return [
        {
          label: `Tên ${this.resourceName.shortName}`,
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: `Mã ${this.resourceName.shortName}`,
          field: 'code',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Lớp ưu tiên',
          field: 'classCode',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Số tiết vắng',
          field: 'absentTime',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'ĐK thi',
          field: 'isEligible',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: this.eligibleFilterDropdownItems,
          },
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Lý do cấm thi',
          field: 'reasonBan',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: this.statusFilterDropdownItems,
          },
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Thao tác',
          field: 'action',
          width: '80px',
          sortable: false,
          tdClass: 'text-center',
        },
      ]
    },
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.CREDIT_CLASS_STUDENT)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.CREDIT_CLASS_STUDENT)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.CREDIT_CLASS_STUDENT)
    },
    viewStudentAbsentLog() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.STUDENT_ABSENT_LOGS)
    },
  },
  watch: {
    'formVangmat.date': function (newVal) {
      this.onChangeDate(newVal)
    },
  },
  async created() {
    this.isLoading = true
    try {
      await Promise.all([
        this.getMainData(this.filter),
        this.getTurns(),
      ])
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
    this.optTurns = this.turns.map(item => ({ value: item.id, text: item.name }))
  },
  methods: {
    ...mapActions({
      getMainData: `${Attr.store}/getData`,
      deleteMainData: `${Attr.store}/deleteData`,
      updateExamConditionsAutomatically: `${Attr.store}/updateExamConditionsAutomatically`,
      getTurns: 'turn/getTurns',
      createAbsent: 'creditClassStudent/createAbsent',
      getSchedulersByDayAndCreditClass: 'schedule/getSchedulersByDayAndCreditClass',
    }),
    onCreateMainData() {
      this.currentMainData = undefined
      this.extMainData = { creditClassId: this.dataSend.id || this.$route.params.id, trainingSystemId: this.dataSend.trainingSystemId }
    },

    async showDetailAbsentTime(data) {
      if(data.absentTime.toString() === '0') {
        return
      }
      if (!this.viewStudentAbsentLog) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Bạn không có quyền xem chức năng này!',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        return
      }
      this.dataSendAbsent = data
      this.$bvModal.show('modal-detail-absent')
    },

    async onUpdateExamConditionsAutomatically() {
      const response = await this.updateExamConditionsAutomatically({ credit_class_id: this.dataSend.id })
      if (response) {
        const { isSuccessful, message } = response
        if (isSuccessful) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          try {
            await this.getMainData(this.filter)
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    onEditMainData(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
    },
    // onAddTeacherIntoCreditClass(mainData) {
    //   const {
    //     rowNum,
    //     originalIndex,
    //     vgt_id,
    //     ...rest
    //   } = mainData
    //   this.currentMainData = rest
    // },
    onDeleteMainData(mainData) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa ${this.resourceName.fullName} <span class="text-danger">${mainData.name}</span> khỏi lớp độc lập?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteMainData(mainData.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getMainData(this.filter)
                  this.$emit('onSuccessModal')
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getMainDataFromStore()
    },
    async getMainDataFromStore() {
      this.isLoading = true
      try {
        await this.getMainData(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getMainDataFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)

        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getMainDataFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      if (Object.hasOwn(columnFilters, 'isEligible')) this.updateParams({ status: columnFilters.isEligible })
      await this.getMainDataFromStore()
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    getEligibleName(id) {
      return this.eligible.find(eligible => eligible.value === id).label
    },
    async onSucceed() {
      await this.getMainDataFromStore()
    },

    showModalVangMat() {
      this.formVangmat.turns = []
      this.formVangmat.date = new Date()
      this.formVangmat.lydo = ''
      this.$bvModal.show('modal-center')
    },
    async selectionChanged(params) {
      this.dsSVChon = params ? params.selectedRows : []
    },
    async onSubmitFormVangMat(event) {
      event.preventDefault()
      const svIds = []
      if (this.dsSVChon.length > 0) {
        this.dsSVChon.forEach(item => {
          svIds.push(item.id)
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn sinh viên!',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        return
      }
      if (this.formVangmat.turns.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn tiết!',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        return
      }
      const dataSend = {
        studentCreditClassIds: svIds,
        absentDate: this.formVangmat.date,
        reason: this.formVangmat.lydo,
        turnNum: this.formVangmat.turns.length,
        turnList: this.formVangmat.turns.toString(),
        sendNoti: this.formVangmat.sendNoti,
        sendSms: this.formVangmat.sendSms
      }
      const r = await this.createAbsent(dataSend)

      if (r.isSuccessful) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: r.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$bvModal.hide('modal-center')
        await this.getMainData(this.filter)
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Có lỗi xẩy ra!',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },

    async onChangeDate(date) {
      this.formVangmat.turns = []
      let dayId = 0
      dayId = typeof date === 'object' ? date.getDay() + 1 : moment(date).day() + 1
      await this.getSchedulersByDayAndCreditClass({ dayId, creditClassId: this.filter.creditClassId })
      if (this.listSchedule.length > 0) {
        this.formVangmat.turns = this.listSchedule.map(item => item.turnId)
      }
    },
  },
}
</script>
